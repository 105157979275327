import dynamic from 'next/dynamic';
import styles from '../styles/Onboarding.module.css';

import { LOGIN_SEO_DESCRIPTION, LOGO_NAME } from '../constants';

const Seo = dynamic(() => import('../head/Seo'));
const LoginSection = dynamic(() => import('../components/LoginSection'));
const LoginTestimonialSection = dynamic(() => import('@/components/LoginTestimonialSection'));

function Home() {
  return (
    <div className={styles.container}>
      <Seo title={LOGO_NAME} desc={LOGIN_SEO_DESCRIPTION} />
      <div className={styles.signupContainer}>
        <div className={styles.leftContainer}>
          <LoginSection />
        </div>
        <div className={styles.rightContainer}>
          <LoginTestimonialSection />
        </div>
      </div>
    </div>
  );
}

Home.theme = 'light';
export default Home;
